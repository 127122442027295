<template>
    <v-dialog v-model="isOpen" width="650px" max-width="95%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Confirmation
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4 px-4">
                {{ text }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="confirm()" depressed small color="primary">
                    {{ confirmText }}
                </v-btn>

                <v-spacer />

                <v-btn @click="cancel()" depressed small>
                    {{ cancelText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmationDialog',

    props: {
        text: { type: String },
        confirmText: { type: String, default: 'Oui' },
        cancelText: { type: String, default: 'Non' }
    },

    data: () => ({
        isOpen: false
    }),

    methods: {
        confirm() {
            this.$emit('confirm');
            this.isOpen = false;
        },

        cancel() {
            this.$emit('cancel');
            this.isOpen = false;
        }
    }
};
</script>
