<template>
    <v-autocomplete v-model="selectedCategories" :items="displayCategories" :loading="isLoading" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" :search-input.sync="search" @focus="$emit('focus')" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedCategories)" :multiple="multiple" :label="label" :placeholder="placeholder" :error-messages="errors" :hide-details="!failed" no-data-text="Commencez à taper pour lancer une recherche" :class="{ 'flex-grow-0': flexGrow0 }" color="app-blue" background-color="white" no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" small label>
                {{ data.item.name }}
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedCategories.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:prepend>
            <slot name="prepend" />
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'AppointmentCategoryAutocomplete',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        flexGrow0: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        errors: { type: Array },
        failed: { type: Boolean, default: false },
        initCategories: { type: Array }
    },

    data: () => ({
        selectedCategories: null,
        categories: [],
        displayCategories: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    watch: {
        value() {
            this.selectedCategories = this.value;
        },

        search(search) {
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length.length < 3) {
                    this.displayCategories = this.categories;
                } else {
                    this.displayCategories = this.categories.filter((p) => {
                        return p.name.toLowerCase().includes(search.toLocaleLowerCase());
                    });
                }
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;
                const query = {
                    limit: 5,
                    quickSearch: search
                };
                this.fetchCategories(query);
            }, 500);
        },

        initCategories() {
            this.displayCategories = this.initCategories;
        }
    },

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun programme disponible';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchCategories(query) {
            try {
                this.isLoading = true;
                const { categories } = await this.repos.appointments.getCategories(query);
                this.categories = categories;
                this.displayCategories = categories;
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        reset() {
            this.selectedCategories = [];
        }
    },

    created() {
        this.selectedCategories = this.value;
        if ((Array.isArray(this.selectedCategories) && this.selectedCategories.length > 0) || typeof this.selectedCategories === 'number') {
            const categories = Array.isArray(this.selectedCategories) ? this.selectedCategories : [this.selectedCategories];
            this.fetchCategories({ categories });
        }
        if (this.initCategories) {
            this.displayCategories = this.initCategories;
        }
    }
};
</script>